/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { ThemeProvider } from 'theme-ui'
import { defineCustomElements } from '@ecotricity/web-components/loader'

import { theme } from './src/styles/theme'
import { WebpProvider } from './src/context/webp'
import { YouTubeProvider } from './src/context/youtube'

defineCustomElements()

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <WebpProvider>
      <YouTubeProvider>
        {element}
      </YouTubeProvider>
    </WebpProvider>
  </ThemeProvider>
)

export const shouldUpdateScroll = ({ routerProps }) => {
  if (!routerProps.location.hash) {
    window.scroll({
      top: 0,
      left: 0,
    })
    return false
  }
}

export const onInitialClientRender = () => {
  window.dispatchEvent(new Event('hydrated'))
  window.__hydrated = true
}