import { Theme } from 'theme-ui'
import arrow from '../images/small-arrow.svg'
import arrowGreen from '../images/small-arrow-green.svg'
import arrowhead from '../images/small-arrowhead.svg'
import { modularScale, fluidTypography } from '../utils/mixins'

const formInput = {
  color: '#151515',
  border: '1px solid #d4d7d7',
  backgroundColor: '#e7eaea',
  borderRadius: '0',
  height: '4.75em',
  transition: 'background-color .75s ease-in-out',
  padding: '1.5884955747rem 1rem',
  display: 'block',
  width: '100%',
  fontWeight: '400',
  lineHeight: '1.5',
  backgroundClip: 'padding-box',
  overflow: 'visible',
  marginBottom: '1rem',
  ':focus': {
    outline: '0',
    boxShadow: '0 0 0 .2rem rgba(0,123,255,.25)',
  },
}

export const theme: Theme = {
  // breakpoints: mobile, tablet, laptop, desktop
  breakpoints: ['640px', '1024px', '1366px', '1680px'],
  space: modularScale(0.125, 1.26, 'rem'),
  fontSizes: fluidTypography(1, [1.21, 1.26], 0.28, 'rem'),
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Lubalin Graph", serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    light: 300,
    body: 400,
    heading: 700,
    bold: 700,
    black: 800,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.1,
  },
  colors: {
    text: '#151515',
    background: '#fff',
    primary: '#9ad43a',
    contrast: '#7ca933',
    secondary: '#3ea0fd',
    accent: '#e54d9f',
    muted: '#e7eaea',
    darkMuted: '#f3f5f5',
    disabled: '#888',
    lightDisabled: '#bdbdbd',
    error: '#f63',
    gridBlue: '#012169',
  },
  borders: {
    single: '0.0625rem solid #151515',
    double: '0.125rem solid #151515',
    active: '0.25rem solid #9ad43a',
    muted: '0.0625rem solid #e7eaea',
    primaryBg: '0.0625rem solid #7ca933',
    activeBg: '0.0625rem solid #9AD43A',
    disabledBg: '0.0625rem solid #828282',
    mutedBg: '0.0625rem solid #d4d7d7',
  },
  borderWidths: {
    single: '0.0625rem',
    double: '0.125rem',
    divider: '1.5rem',
  },
  shadows: {
    card: '0 0 1rem 0.3rem rgba(0,0,0,0.1)',
  },
  badges: {
    new: {
      color: 'background',
      bg: 'primary',
      padding: '0.125rem 0.25rem',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      color: 'text',
    },
    a: {
      color: 'text',
      fontWeight: 'bold',
    },
  },
  layout: {
    container: {
      maxWidth: '76%',
    },
    wide: {
      maxWidth: '92%',
    },
    full: {
      maxWidth: 'none',
    },
  },
  grids: {
    default: {
      columnGap: 11,
      rowGap: 14,
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
    equal: {
      gap: 11,
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
  },
  text: {
    default: {
      fontSize: 0,
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    intro: {
      fontSize: 1,
      fontFamily: 'body',
      fontWeight: 'light',
      lineHeight: 'body',
    },
    head: {
      fontSize: 2,
      fontFamily: 'body',
      fontWeight: 'light',
      lineHeight: 'body',
    },
    hero: {
      fontSize: 2,
      fontFamily: 'body',
      fontWeight: 'light',
      lineHeight: 'body',
    },
    primary: {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      lineHeight: 'heading',
    },
    label: {
      fontWeight: 'bold',
    },
    errorLabel: {
      marginTop: '8px',
      fontSize: 'min(max(75%,.83vw),112.5%)',
      minHeight: '1rem',
      color: 'error',
    },
    caption: {
      fontSize: '0.94rem',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    xl: {
      fontSize: 7,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    lrg: {
      fontSize: 5,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    med: {
      fontSize: 3,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 1.2,
    },
    sml: {
      fontSize: 2,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 1.3,
    },
    xs: {
      fontSize: 1,
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 1.3,
    },
  },
  links: {
    nav: {
      display: 'block',
      color: 'inherit',
      fontSize: '1rem',
      textDecoration: 'none',
      fontWeight: 'body',
      '&.active': {
        fontWeight: 'bold',
      },
      '&:hover': {
        color: 'contrast',
      },
    },
    tab: {
      overflow: 'hidden',
      display: 'flex',
      position: 'relative',
      fontSize: '0.94rem',
      color: 'inherit',
      backgroundColor: 'muted',
      textDecoration: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      border: 'mutedBg',
      '&.active': {
        fontSize: '1rem',
        fontWeight: 'bold',
        border: 'none',
        backgroundColor: 'background',
      },
      '&.active::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '65%',
        margin: 'auto 0',
        bottom: 0,
        borderBottom: 'active',
      },
      '&:hover': {
        backgroundColor: 'background',
        color: 'contrast',
      },
    },
    button: {
      display: 'inline-block',
      color: 'text',
      textAlign: 'center',
      textDecoration: 'none',
      backgroundColor: 'primary',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'primaryBg',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      '&:hover': {
        color: 'background',
        backgroundColor: 'primary',
        borderColor: '#95C93E',
      },
    },
    buttonDisabled: {
      display: 'inline-block',
      color: 'text',
      textAlign: 'center',
      textDecoration: 'none',
      backgroundColor: 'lightDisabled',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'disabledBg',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      '&:hover': {
        color: 'background',
        backgroundColor: 'lightDisabled',
        borderColor: 'disabledBg',
      },
    },
    buttonSecondary: {
      display: 'inline-block',
      color: 'text',
      textDecoration: 'none',
      textAlign: 'center',
      backgroundColor: 'background',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'single',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      '&:hover': {
        color: 'contrast',
        border: 'primaryBg',
      },
    },
    buttonAlt: {
      display: 'inline-block',
      color: 'background',
      textDecoration: 'none',
      textAlign: 'center',
      backgroundColor: 'text',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'single',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      '&:hover': {
        color: 'text',
        backgroundColor: 'muted',
        borderColor: 'text',
      },
    },
    filter: {
      display: 'inline-block',
      color: 'text',
      textDecoration: 'none',
      textAlign: 'center',
      backgroundColor: 'muted',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'mutedBg',
      borderRadius: 0,
      minWidth: '8em',
      paddingY: 8,
      paddingX: 12,
      cursor: 'pointer',
      '&.active': {
        backgroundColor: 'primary',
        border: 'primaryBg',
      },
    },
    more: {
      display: 'inline-block',
      color: 'text',
      textDecoration: 'none',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'single',
      width: '100%',
      maxWidth: '20em',
      textAlign: 'center',
      paddingY: 7,
      paddingX: 13,
      '&:hover': {
        color: 'contrast',
        border: 'primaryBg',
      },
    },
    anchor: {
      position: 'relative',
      fontWeight: 'bold',
      color: 'text',
      fontSize: '0.94rem',
      textDecoration: 'none',
      paddingLeft: '5.4rem',
      lineHeight: '4.2rem',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '4rem',
        height: '4rem',
        border: 'single',
        borderRadius: '50%',
        backgroundImage: `url(${arrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '55% center',
        backgroundSize: '65%',
      },
      '&.flip::before': {
        transform: 'rotate(180deg)',
      },
      '&:hover': {
        color: 'contrast',
      },
      '&:hover::before': {
        backgroundImage: `url(${arrowGreen})`,
        borderColor: 'contrast',
      },
    },
    label: {
      color: 'text',
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': {
        color: 'contrast',
      },
    },
    primary: {
      color: 'primary',
      fontWeight: 'bold',
      '&:hover': {
        color: 'background',
      },
    },
    alt: {
      color: 'background',
      fontWeight: 'bold',
      '&:hover': {
        color: 'primary',
      },
    },
    plain: {
      color: 'text',
      textDecoration: 'none',
    },
  },
  forms: {
    input: {
      color: 'text',
      backgroundColor: 'muted',
      fontFamily: 'body',
      fontWeight: 'bold',
      width: 'auto',
      border: 'mutedBg',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      cursor: 'pointer',
    },
    complexFormInput: {
      // a variant for larger forms, emulated from switch frontend
      variant: 'theme.forms.input',
      ...formInput,
    },
    error: {
      variant: 'theme.forms.input',
      ...formInput,
      borderColor: 'error',
    },
    smallSelect: {
      appearance: 'none',
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      border: 'mutedBg',
      borderRadius: 0,
      minWidth: '16em',
      paddingY: 8,
      paddingLeft: '1.5rem',
      paddingRight: '4rem',
      backgroundColor: 'muted',
      backgroundImage: `url(${arrowhead})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 1.5rem center',
      backgroundSize: '1rem',
      cursor: 'pointer',
    },
    label: {
      marginBottom: '0.3rem',
      fontSize: '1.125rem',
    },
    textarea: {
      ...formInput,
      height: '8rem',
      padding: '1rem',
      margin: '0 0 2rem',
    },
    select: {
      '& + svg': {
        marginLeft: '-30px',
      },
    },
    radioGrey: {
      width: '30px',
      height: '30px',
      marginRight: '15px',
    },
  },
  buttons: {
    primary: {
      color: 'text',
      backgroundColor: 'primary',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'primaryBg',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      cursor: 'pointer',
      '&:hover': {
        color: 'background',
        backgroundColor: 'primary',
        borderColor: '#95C93E',
      },
    },
    secondary: {
      color: 'text',
      backgroundColor: 'background',
      fontWeight: 'bold',
      border: 'single',
      borderRadius: '10rem',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      cursor: 'pointer',
      '&:hover': {
        color: 'contrast',
        border: 'primaryBg',
      },
    },
    alt: {
      color: 'text',
      backgroundColor: 'primary',
      fontWeight: 'bold',
      border: 'single',
      borderRadius: '10rem',
      borderColor: 'contrast',
      minWidth: '10em',
      paddingY: 11,
      paddingX: 13,
      cursor: 'pointer',
      '&:hover': {
        color: 'background',
        backgroundColor: 'primary',
        borderColor: 'contrast',
      },
    },
    icon: {
      padding: 0,
      cursor: 'pointer',
      img: { width: '100%' },
    },
    square: {
      width: '5rem',
      height: 'auto',
      borderLeft: ['none', null, 'muted'],
      borderRadius: 0,
      cursor: 'pointer',
      img: { height: '1.5rem' },
    },
    rect: {
      color: 'text',
      backgroundColor: 'primary',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'primaryBg',
      borderRadius: '0',
      minWidth: '14em',
      paddingY: 10,
      paddingX: 12,
      cursor: 'pointer',
      '&:hover': {
        color: 'background',
        backgroundColor: 'primary',
        borderColor: '#95C93E',
      },
    },
    rectSecondary: {
      color: 'text',
      backgroundColor: 'background',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'single',
      borderRadius: '0',
      minWidth: '14em',
      paddingY: 10,
      paddingX: 12,
      cursor: 'pointer',
      '&:hover': {
        color: 'contrast',
        border: 'primaryBg',
      },
    },
    round: {
      padding: 6,
      width: '3.6rem',
      height: '3.6rem',
      cursor: 'pointer',
      backgroundColor: 'text',
      borderRadius: '50%',
      img: { width: '100%', filter: 'invert(1)' },
    },
  },
}
