exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-business-index-tsx": () => import("./../../../src/templates/business-index.tsx" /* webpackChunkName: "component---src-templates-business-index-tsx" */),
  "component---src-templates-business-page-tsx": () => import("./../../../src/templates/business-page.tsx" /* webpackChunkName: "component---src-templates-business-page-tsx" */),
  "component---src-templates-carbon-calculator-index-tsx": () => import("./../../../src/templates/carbon-calculator/index.tsx" /* webpackChunkName: "component---src-templates-carbon-calculator-index-tsx" */),
  "component---src-templates-carbon-calculator-question-page-tsx": () => import("./../../../src/templates/carbon-calculator/question-page.tsx" /* webpackChunkName: "component---src-templates-carbon-calculator-question-page-tsx" */),
  "component---src-templates-carbon-calculator-results-tsx": () => import("./../../../src/templates/carbon-calculator/results.tsx" /* webpackChunkName: "component---src-templates-carbon-calculator-results-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-form-tsx": () => import("./../../../src/templates/form.tsx" /* webpackChunkName: "component---src-templates-form-tsx" */),
  "component---src-templates-green-electricity-tsx": () => import("./../../../src/templates/green-electricity.tsx" /* webpackChunkName: "component---src-templates-green-electricity-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-live-generation-tsx": () => import("./../../../src/templates/live-generation.tsx" /* webpackChunkName: "component---src-templates-live-generation-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news-index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-our-mission-tsx": () => import("./../../../src/templates/our-mission.tsx" /* webpackChunkName: "component---src-templates-our-mission-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-partners-archive-tsx": () => import("./../../../src/templates/partners-archive.tsx" /* webpackChunkName: "component---src-templates-partners-archive-tsx" */),
  "component---src-templates-partners-index-tsx": () => import("./../../../src/templates/partners-index.tsx" /* webpackChunkName: "component---src-templates-partners-index-tsx" */),
  "component---src-templates-support-page-tsx": () => import("./../../../src/templates/support-page.tsx" /* webpackChunkName: "component---src-templates-support-page-tsx" */),
  "component---src-templates-support-sub-page-tsx": () => import("./../../../src/templates/support-sub-page.tsx" /* webpackChunkName: "component---src-templates-support-sub-page-tsx" */),
  "component---src-templates-vegan-energy-tsx": () => import("./../../../src/templates/vegan-energy.tsx" /* webpackChunkName: "component---src-templates-vegan-energy-tsx" */)
}

